import $ from 'jquery';
import {initFormValidation} from './validation';

let child_count = 1;
let parent_count = 1;

const addChildForm = function () {
    const index = child_count;
    child_count++;

    const $element = $('#children fieldset#child_0').clone();

    // Set id on the new element
    $element.attr('id', 'child_' + index);
    $('legend .num', $element).text(index + 1);
    $('legend', $element).append('<div class="btn btn-outline-secondary btn-sm float-right delete">' + Lang.get('signup.remove_child') + '</div>');
    $('input, select', $element).each(function () {
        // clear all input in the cloned element
        if (!$(this).is('input[name="child[0][last_name]"]') && !$(this).is('select')) {
            $(this).val('');
        }
        // set new name and id for the cloned element input
        $(this).attr('name', $(this).attr('name').replace(0, index));
        $(this).attr('id', $(this).attr('id').replace(0, index));
    });

    // update the for attr in all labels
    $('label', $element).each(function () {
        $(this).attr('for', $(this).attr('for').replace(0, index));
    });

    $('#children').append($element);
};

const addGuardianForm = function () {
    const index = parent_count;
    parent_count++;

    const $element = $('#parents fieldset#parent_0').clone();

    // Set id on the new element
    $element.attr('id', 'parent_' + index);
    $('legend .num', $element).text(index + 1);
    $('legend', $element).append('<div class="btn btn-outline-secondary btn-sm float-right delete">' + Lang.get('signup.remove_parent') + '</div>');

    $('.parent_type-trigger', $element).attr('href', '#parent_type_' + index);
    $('#parent_type_0', $element).attr('id', 'parent_type_' + index);

    $('input', $element).each(function () {
        // clear all input in the cloned element
        if (!$(this).is('input[name="parent[0][last_name]"]') && !$(this).is('input[name="parent[0][parent_type]"]')) {
            $(this).val('');
        }

        // set new name and id for the cloned element input
        $(this).attr('name', $(this).attr('name').replace(0, index));
        $(this).attr('id', $(this).attr('id').replace(0, index));
    });

    // update the for attr in all labels
    $('label', $element).each(function () {
        $(this).attr('for', $(this).attr('for').replace(0, index));
    });

    $('#parents').append($element);
};

$(document).ready(function () {
    const $signupForm = $('#signup-form, #guest-form');

    if ($signupForm.length > 0) {
        initFormValidation($signupForm);

        $('[name="child[0][last_name]"]').change(function () {
            const $parentLastName = $('[name="parent[0][last_name]"]');

            if ($parentLastName.val() === '') {
                $parentLastName.val($(this).val());
            }
        });

        $(document).on('click', '.delete', function () {
            $(this).closest('fieldset').remove();
        });

        $('#add-child').click(function (e) {
            e.preventDefault();
            addChildForm();
        });

        $('#add-parent').click(function (e) {
            e.preventDefault();
            addGuardianForm();
        });

        /*
         * Disable Submit button on submit
         */
        $signupForm.submit(function () {
            if ($(this).valid()) {
                $('form .btn-primary')
                    .prop('disabled', true)
                    .html(Lang.get('signup.saving') + ' <i class="fa-solid fa-circle-o-notch fa-spin"></i>');
            }
        });

        $('#church_id').change((event) => {
            const churchId = event.target.value;
            const $campusSelect = $('#campus_id');
            $campusSelect.addClass('js-loading');
            $.getJSON('/api/campuses?church_id=' + churchId)
                .done(response => {
                    $campusSelect.empty();
                    response.forEach(campus => {
                        $campusSelect.append('<option value="' + campus.id + '">' + campus.name + '</option>');
                    });
                    $campusSelect.removeClass('js-loading');
                });
        });
    }
});

import $ from "jquery";
import * as EventBus from './event-bus';

const $document = $(document);

$document.ready(function () {
    const modal = $('#manage-modal');
    if (modal.length === 0) {
        // modal not found on page, no need to continue
        return;
    }

    /*
     *  Listen for the openModal event and show the modal with the url from the event payload
     */
    EventBus.on('openModal', url => {
        const content = $('.modal-content', modal);

        // Add spinner while loading modal skeleton
        content.html('<div class="text-center" style="margin-top: 100px;"><i class="fa-solid fa-spinner fa-spin fa-3x"></i></div>');

        // show the modal
        modal.modal({
            backdrop: 'static',
            keyboard: true
        });

        // load the content
        content.load(url, () => {
            // when content is loaded, trigger the first tab
            $('#modal-tabs a:first').tab('show');
        });
    })


    /*
     * Submit forms that have class "ajax" using jQuery ajax
     */
    $document.on('submit', 'form.ajax', function (e) {
        e.preventDefault();

        const $alertWrapper = $('#alert-wrapper');
        const data = $(this).serialize();
        const url = $(this).attr('action');
        let request_type = $(this).attr('method');
        if ($(this).children('input[name="_method"]').length > 0) {
            request_type = $(this).children('input[name="_method"]').val();
        }

        $alertWrapper.empty();

        $.ajax({
            url: url,
            type: request_type,
            data: data
        })
            .done(function (response) {
                $('form .btn-primary').prop('disabled', false);
                $alertWrapper.append(
                    createAlertHtml('alert-success', response)
                );
            })
            .fail(function (response) {
                if (response.responseJSON?.message) {
                    $alertWrapper.append(
                        createAlertHtml('alert-danger', response.responseJSON.message)
                    );
                }
            });
    });

    /*
     * Confirm all clicks on delete buttons and links
     */
    $document.on('click', 'a.delete, button.delete, input.delete', function () {
        return confirm("Are you sure you want to delete?");
    });

    /*
     *  Hide modal for current person and open for selected person
     */
    $document.on('click', 'a.open-modal', function (e) {
        e.preventDefault();
        EventBus.emit('openModal', $(this).attr('href'));
    });

    /*
     * Load new page content on tab switch in the modal
     */
    modal.on('show.bs.tab', function (e) {
        // get the tab container of the activated tab
        const tabTrigger = $(e.target);
        const tabContentTarget = tabTrigger.attr('href');
        const $tabContainer = $(tabContentTarget);
        // get the href to load into the container
        const tabHref = tabTrigger.data('href');

        if (tabHref) {
            $tabContainer.html('<div class="text-center" style="margin-top: 25px;"><i class="fa-solid fa-spinner fa-spin fa-3x"></i></div>');

            $tabContainer.load(tabHref, function (response, status) {
                if (status === "error") {
                    $tabContainer.html('Page not found');
                }
            });
        } else {
            $tabContainer.html('No url found for this tab');
        }
    });
});

function createAlertHtml(type, text) {
    return `
        <div class="alert ${type} alert-dismissible fade show" role="alert">
            ${text}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    `;
}

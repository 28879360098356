import $ from "jquery";

const showErrorCountSummary = function (count) {
    if (count > 0) {
        const message = (count === 1)
            ? Lang.get('validation.one_invalid_input')
            : Lang.get('validation.n_invalid_input', {num_invalid: count});
        $('#error-summary span').text(message);
        $('#error-summary').show();
    } else {
        $('#error-summary').hide();
    }
};
/**
 * Init jQuery Validation on a form
 *
 * @param form the jQuery selector of the form to validate
 */
export const initFormValidation = function (form) {
    $.validator.addClassRules('email', {
        email: true,
        remote: {
            url: "/api/validate/email",
            method: 'get',
            data: {
                locale: function() {
                    return locale;
                }
            }
        }
    });

    $.validator.addClassRules('zip', {
        digits: true
    });

    let formSubmitted = false;
    form.validate({
        errorClass: 'invalid-feedback',
        highlight: function (element) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function (element) {
            $(element).removeClass('is-invalid');
        },
        errorPlacement: function (error, element) {
            if (element.is('input[type=radio]')) { // checkbox and radio after group
                element.closest('.form-group').append(error);
            } else {    // default after element
                element.after(error);
            }
        },
        showErrors: function (errorMap, errorList) {
            if (formSubmitted) {
                const errorLiArray = $.map(errorList, function (error) {
                    // get placeholder label
                    let labelText = $(error.element).prev('label').text();
                    if (labelText === undefined || labelText === '') {
                        labelText = $(error.element).attr('placeholder');
                    }

                    return '<li>' + labelText + ': ' + error.message + '</li>';
                });

                $('#error-summary-list').html(errorLiArray.join(''));
                formSubmitted = false;
            } else {
                $('#error-summary-list').empty();
            }

            showErrorCountSummary(this.numberOfInvalids());

            // show the individual error messages
            this.defaultShowErrors();
        },
        invalidHandler: function (event, validator) {
            const errors = validator.numberOfInvalids();
            if (errors) {
                showErrorCountSummary(errors);
            } else {
                $('#error-summary').hide();
            }
            formSubmitted = true;
        }
    });
};

import Lang from 'lang.js'

// setup some dependencies and systems
import './setup-sentry';
import './setup-jquery';
import './ios-stay-standalone';

// global libraries
import 'bootstrap';

// plugins
import './plugins/jquery.bootstrap-growl.min.js';
import 'jquery-validation';
import './plugins/jquery.validate.messages_sv.js';

// js code
import './global.js';
import './growl.js'
import './new.js'

// admin js code
import './admin.js'
import './duplicate-family.js'

// Setup Lang
import messages from '../../lang/messages.json';
window.Lang = new Lang({
    locale: locale || 'sv',
    messages: messages,
});
